import { useEffect, useState } from 'react';
import { globalStore } from '~/store';
import { getHash } from '~/utils';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';

async function getAudioFingerprint(): Promise<{ hash: string; data: any }> {
  const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
  const analyser = audioContext.createAnalyser();
  const oscillator = audioContext.createOscillator();
  oscillator.connect(analyser);

  oscillator.start();
  analyser.fftSize = 2048;
  const bufferLength = analyser.frequencyBinCount;
  const dataArray = new Uint8Array(bufferLength);
  analyser.getByteFrequencyData(dataArray);

  const data = {
    sum: dataArray.reduce((a, b) => a + b, 0),
    gain: analyser.maxDecibels,
    freq: analyser.frequencyBinCount,
    time: audioContext.currentTime,
    trap: dataArray[0],
    unique: dataArray.length,
    channelCount: analyser.channelCount,
    channelCountMode: analyser.channelCountMode,
    channelInterpretation: analyser.channelInterpretation,
    sampleRate: audioContext.sampleRate,
    fftSize: analyser.fftSize,
    frequencyBinCount: analyser.frequencyBinCount,
    maxDecibels: analyser.maxDecibels,
    minDecibels: analyser.minDecibels,
    numberOfInputs: analyser.numberOfInputs,
    numberOfOutputs: analyser.numberOfOutputs,
    smoothingTimeConstant: analyser.smoothingTimeConstant,
  };

  const hash = await getHash(data);
  return { hash, data };
}

const AudioFP: React.FC = () => {
  const [fingerprint, setFingerprint] = useState<{ hash: string; data: any } | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const fingerprint = await getAudioFingerprint();
      setFingerprint(fingerprint);
      globalStore.set({ ...globalStore.get(), audiofp: fingerprint });
    };
    fetchData();
  }, []);

  return (
    <div>
      <h2 className="text-3xl font-medium mb-5 flex items-center gap-2">
        Audio FP <span className="text-sm border px-1">{fingerprint?.hash.slice(0, 12)}</span>
      </h2>
      <div className="overflow-auto border p-2 rounded-lg border-slate-200 h-auto max-h-[500px]">
        <JsonView src={fingerprint} />
      </div>
    </div>
  );
};

export default AudioFP;
